div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 300px;
  display: inline-block;
  margin-top: 20px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  margin: 0;
  font-size: 12px;
  width: 100%;
  background-color: #f5f8fa;
  padding: 15px;
  border: transparent;
  outline: transparent;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #eee;
  background: #faf9f9;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 5px;
  cursor: move;
  border-radius: 2px;
  padding: 10px;
}
.ReactTags__remove {
  background-color: #d07171;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  border: 0;
  font-size: 15px;
  padding: 0px 5px;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.uppload-modal {
  width: 98vw !important;
  height: 90vh !important;
}

.effects-continue {
  padding: 12px;
  padding-right: 20px;
}

.effects-continue--cancel {
  display: none !important;
}

.carousel-indicators {
  visibility: hidden;
}

.carousel-control-prev {
  background-color: rgba(0, 0, 0, 0.314) !important;
  height: 40px !important;
  width: 40px !important;
  top: 20px !important;
}

.carousel-control-next {
  background-color: rgba(0, 0, 0, 0.314) !important;
  height: 40px !important;
  width: 40px !important;
  top: 20px !important;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 450px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

.modal.left.fade .modal-dialog {
  left: 0;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

.modal.right.fade .modal-dialog {
  right: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.custom-card-hover:hover {
  border-radius: 20px;
  box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
  -webkit-box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
  -moz-box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
}

.custom-card-active {
  border-radius: 20px;
  box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
  -webkit-box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
  -moz-box-shadow: -2px 1px 19px 2px rgba(0, 158, 247, 0.75);
}

.igCwJF {
  width: 100% !important;
}
.cKqDMA {
  width: 100% !important;
}
.bqJndB {
  display: block !important;
}

.gallery-item {
  margin: 5px;
}

.file-types {
  max-width: 200px !important;
}

.vjs-big-play-button {
  font-size: 2em !important;
}

.vjs-big-play-centered {
  overflow: hidden;
}

.sidenav1 {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #181c32 !important;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0px 0px 8px #888888;
}

.sidenav1 a {
  padding: 8px 8px 8px 16px;
  text-decoration: none;
  font-size: 16px;
  color: white;
  display: block;
  transition: 0.3s;
}

.sidenav1 a:hover {
  color: #f1f1f1;
}

.sidenav1::-webkit-scrollbar {
  display: none;
}

.animated-icon1 {
  width: 25px;
  height: 13px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.animated-icon1 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.animated-icon1 span {
  background: white;
}

.animated-icon1 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon1 span:nth-child(2) {
  top: 5px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon1 span:nth-child(3) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.animated-icon1.open1 span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
  left: 8px;
}

.animated-icon1.open1 span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.animated-icon1.open1 span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 21px;
  left: 8px;
}

.bNCCdn {
  height: inherit;
}

.gVmiLs {
  height: inherit;
  object-fit: scale-down;
}

.uppy-Dashboard-progressindicators {
  display: none !important;
}

.uppy-Dashboard-AddFiles-info {
  display: none !important;
}

.rstcustom__rowWrapper:hover {
  opacity: 1 !important;
}

.rst__placeholder {
  display: none !important;
}

.rstcustom__row {
  width: 100% !important;
}

.rstcustom__rowLabel {
  flex: 1 !important;
}

/* [data-viewport-type='element'] {
  width: auto !important;
} */


/* editor styling */

.btn-round{
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 550;
  color: #181818;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none !important;
  
}

.btn-round:hover {
  background-color: #EDEDED;
}

.btn-light-icon{
  background-color: white;
  border-radius: 10px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 550;
  color: #181818;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.btn-light-icon:hover {
  background-color: #EDEDED;
}

.btn-dark-icon{
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 550;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-dark-icon:hover {
  background-color: #454545;
  border-radius: 10px;
  padding: 6px 12px;
}

.btn-dark-transparent{
  background-color: #181818;
  border: 2px solid white ;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 14px;
  font-weight: 550;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-dark-transparent:hover {
  background-color: #454545;
}

.btn-light-transparent{
  background-color: white;
  border: 2px solid #181818;
  border-radius: 5px;
  padding: 8px 18px;
  font-size: 14px;
  font-weight: 550;
  color: #181818;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-light-transparent:hover {
  background-color: #EDEDED;
}

.color-white{
  color: white !important;
}

.color-dark{
  color: #181818 !important;
}

.hover-effect-light:hover{
  background-color: rgb(249, 249, 249) ;
  opacity: 0.8;
}

.hover-effect-dark:hover{
  background-color: rgba(49, 47, 47, 0.5) ;
  opacity: 0.8;
}

.hover-effect-light-sidebar:hover{
  background-color: rgb(249, 249, 249) ;
  opacity: 0.8;
}

.hover-effect-light-sidebar:hover .menu-title{
  color: #181818 !important;
}

.input-focus-dark:focus{
  border: 2px solid white !important;

}

.input-focus-light:focus{
  border: 2px solid #181818 !important;
}

/* table */
.custom-table tbody tr {
  border-bottom: 1px solid #e0e0e0; /* Light gray color for the border */
}

.custom-table tbody tr:last-child {
  border-bottom: none; /* Remove border for the last row */
} 

.table-container {
  max-height: calc(100vh - 200px); 
  overflow-y: auto; 

}
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #F5F8FA;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
 


/* sidebar */
@media only screen and (max-width: 1500px) {
  .dam-container {
    height: 70vh !important;
  }

  .img-editor {
    height: 87vh;
  }

  .resize-bar {
    height: 52vh;
  }
}

@media only screen and (max-width: 1024px) {
  .dam-container {
    height: 68vh !important;
  }

  .resize-bar {
    height: 44vh;
  }
}

